  <template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
      <Header></Header>
     
      <!-- Rest of the HOME PAGE STARTS -->
     
      <component v-bind:is="templateName"></component>
          
      <!-- footer section -->
        <section class="footer">
            <footer>
                <cookie-law theme="dark-lime"></cookie-law>
            </footer>
            <Footer/>
        </section>
    </main>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Blog from "@/views/Blog";
import News from "@/views/News";
// const _ = require('lodash');
// import { mapGetters } from "vuex";

export default {
  name: "Pages",
  components: {
    Blog,News,
    Header,
    Footer,
  },
  computed: {
     templateName() {
      return this.$route.params.slug;
    },
   
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
  mounted() {
    this.scrollToTop()
  },

 methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
